.l-contact {
  opacity: 0;
  transition: all cubic-bezier(0.23, 0.6, 0.43, 0.99) 1s;
  transform: translateY(30px);

  &.is-active {
    opacity: 1;
    transform: translateY(0);
  }
}

.l-contact--lower {
  opacity: 1;
  transform: translateY(0);
}

.l-contact__link {
  position: relative;
  display: block;
  padding: 70px 0;
  color: #fff;
  text-align: center;
  background-color: #000;
  // background-color: var(--secondarycolor);

  @include mq(md) {
    padding: 92px 0 98px;
    transition: background-color 0.25s;
  }

  &:hover {
    @include mq(md) {
      background-color: #f23360;
      // background-color: var(--basecolor);
    }
  }
}

.l-contact__heading {
  font-family: var(--font-en);
  font-size: 73.465px;
  font-weight: 500;
  letter-spacing: 0.03em;

  @include mq(md) {
    font-size: 95px;
    letter-spacing: 0;
  }
}

.l-contact__lede {
  margin-top: 3px;

  @include mq(md) {
    margin-top: 15px;
    font-size: 20px;
    letter-spacing: 0.15em;
  }
}

.l-contact__icon {
  width: 53px;
  margin-top: 45px;

  @include mq(md) {
    position: absolute;
    top: 0;
    right: 120px;
    bottom: 0;
    width: 43px;
    margin: auto 0;
  }
}
