.c-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55px;
  font-family: var(--font-en);
  color: #fff;
  border: 1px solid #fff;

  @include mq(md) {
    width: 390px;
    height: 80px;
    font-size: 16px;
    transition: all 0.25s;
  }

  &::before {
    position: absolute;
    top: 0;
    right: 25px;
    bottom: 0;
    width: 14px;
    height: 5px;
    margin: auto 0;
    content: '';
    background-image: url('/assets/images/pages/common/icon_arrow.svg');
    background-size: contain;

    @include mq(md) {
      right: 30px;
    }
  }

  &:hover {
    @include mq(md) {
      background-color: var(--secondarycolor);
      border: 1px solid var(--secondarycolor);
    }
  }
}

.c-button._black {
  color: #222;
  border: 1px solid #222;

  &::before {
    background-image: url('/assets/images/pages/common/icon_arrow_black.svg');
  }

  &:hover {
    @include mq(md) {
      color: #fff;
      background-color: #ee1740;
      border: 1px solid #ee1740;

      &::before {
        background-image: url('/assets/images/pages/common/icon_arrow.svg');
      }
    }
  }
}

.c-button._black._blank {
  &::after {
    position: relative;
    top: -15px;
    right: -105px;
    display: inline-block;
    width: 10px;
    height: 11px;
    content: '';
    background-image: url('/assets/images/pages/common/icon_blank_black.svg');
    background-size: contain;

    @include mq(md) {
      top: -25px;
      right: -130px;
    }
  }

  &:hover {
    @include mq(md) {
      &::after {
        background-image: url('/assets/images/pages/common/icon_blank_white.svg');
      }
    }
  }
}
