.l-footer {
  //
}

.l-footer__container {
  //
}

.l-footer__list {
  //
}

.l-footer__list-item {
  font-size: 13px;
  color: #fff;
  letter-spacing: 0.1em;
}

.l-footer__link,
.l-footer__title {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 25px;
  background-color: #6e6e6e;
}

.l-footer__link {
  position: relative;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    right: 12.5px;
    bottom: 0;
    width: 12.5px;
    height: 1px;
    margin: auto 0;
    content: '';
    background-color: #fff;
  }

  &::after {
    transition: transform 0.25s;
    transform: rotate(-90deg);
  }

  &.is-expand {
    &::after {
      transform: rotate(0);
    }
  }
}

.l-footer__block {
  height: 0;
  overflow: hidden;
  transition: height 0.25s;
}

.l-footer__item {
  //
}

.l-footer__title {
  //
}

.l-footer__lower {
  //
}

.l-footer__lower-list {
  //
}

.l-footer__lower-item {
  //
}

.l-footer__lower-link {
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  padding: 0 25px;
  background-color: #7c7c7c;
}

/**
 * PC ナビスタイル
 */

.l-footer__nav {
  @include mq(md) {
    padding: 32px 90px 37px;
    color: #fff;
    background-color: #6e6e6e;
  }
}

.l-footer__nav-inner {
  display: flex;
  max-width: 1300px;
  margin: 0 auto;

  &:nth-child(n + 2) {
    padding-top: 4px;
    margin-top: 11px;
    border-top: 1px solid #fff;
  }
}

.l-footer__nav-title {
  width: 130px;
  margin-top: 7px;
  margin-right: 70px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0;
}

.l-footer__group {
  width: calc(100% - (130px + 70px));
}

.l-footer__group-item {
  display: inline-block;
  margin-right: 26px;
  font-size: 12px;
  letter-spacing: 0;
}

.l-footer__group-link {
  margin-right: 7px;

  &::after {
    position: relative;
    top: 3px;
    display: inline-block;
    width: 13px;
    height: 14px;
    margin-left: 2px;
    content: '';
    background-image: url('/assets/images/pages/common/icon_blank.svg');
    background-size: contain;
  }

  &:hover {
    text-decoration: underline;
  }
}

.l-footer__aside {
  padding: 3px 0 18px;

  @include mq(md) {
    padding: 24px 0 33px;
  }
}

.l-footer__aside-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.l-footer__aside-item {
  margin-top: 12px;

  @include mq(md) {
    margin-top: 0;
  }

  &:not(:last-child) {
    position: relative;

    &::after {
      position: relative;
      top: -1px;
      font-size: 9px;
      content: '|';
    }
  }
}

.l-footer__aside-link {
  padding: 0 2px 0 4px;
  font-size: 13px;
  color: #4d4d4f;
  letter-spacing: 0.1em;

  @include mq(md) {
    padding: 0 2px 0 5px;
    font-size: 11px;
  }
}

.l-footer__aside-link img {
  width: 13px;
}

.l-footer__copyright {
  padding-top: 25px;
  margin-top: 20px;
  text-align: center;
  border-top: 2px solid var(--secondarycolor);

  @include mq(md) {
    padding-top: 25px;
    margin-top: 23px;
    border-top-width: 3px;
  }
}

.l-footer__copyright small {
  font-size: 12px;
  line-height: 1.75;
  color: #4d4d4f;
  letter-spacing: 0.05em;

  @include mq(md) {
    font-size: 11px;
  }
}
