.l-main {
  overflow: hidden;
}

.l-main--lower {
  margin-top: 90px;

  @include mq(md) {
    position: relative;
    margin-top: 96px;
  }
}
