.l-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 90px;
  background-color: #fff;
  border-bottom: 6px solid #b4b4b4;

  @include mq(md) {
    height: 96px;
    border-top: 3px solid var(--secondarycolor);
    border-bottom: 1px solid #b4b4b4;
  }
}

.l-header__container,
.l-header__group {
  height: 100%;
}

.l-header__container {
  @include mq(md) {
    max-width: 1390px;
    padding: 0 45px;
    margin: 0 auto;
  }
}

.l-header__block {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.l-header__logo {
  display: flex;
  align-items: center;
  padding-left: 18px;

  @include mq(md) {
    padding-left: 0;
  }
}

.l-header__logo img {
  height: 56px;

  @include mq(md) {
    width: 180px;
    height: auto;
  }
}

.l-header__menu {
  //
}

.l-header__menu-button {
  position: relative;
  width: 60px;
  height: 84px;
  background-color: var(--basecolor);
}

.l-header__menu-button span {
  position: absolute;
  left: 17.5px;
  display: inline-block;
  width: 25px;
  height: 1px;
  background-color: #fff;
  transition: all 0.3s;

  &:nth-child(1) {
    top: 30px;
  }

  &:nth-child(2) {
    top: 40px;
  }

  &:nth-child(3) {
    top: 50px;
    width: 16px;
  }
}

.l-header__menu-button.is-expand span {
  &:nth-child(1) {
    transform: translateY(10px) rotate(45deg);
  }

  &:nth-child(2) {
    opacity: 0;
  }

  &:nth-child(3) {
    width: 25px;
    transform: translateY(-10px) rotate(-45deg);
  }
}

.l-header__nav {
  position: absolute;
  top: 0;
  right: -100%;
  width: 100%;
  height: calc(100vh - 60px);
  padding-bottom: 76px;
  margin-top: 60px;
  overflow-y: auto;
  color: #fff;
  background-color: var(--basecolor);
  transition: right 0.6s $ease-out-quart;

  &.is-expand {
    right: 0;
  }
}

.l-header__list {
  //
}

.l-header__list-item {
  transition: background-color 0.25s;

  &:nth-child(n + 2) {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }

  &:last-child {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  &.is-active {
    background-color: rgba(255, 255, 255, 0.05);
  }
}

.l-header__link {
  position: relative;
  display: flex;
  align-items: center;
  height: 54px;
  padding: 0 25px;
  font-weight: 500;

  &:not(.l-header__link--acc) {
    &::before {
      position: absolute;
      top: 0;
      right: 25px;
      bottom: 0;
      width: 14px;
      height: 5px;
      margin: auto 0;
      content: '';
      background-image: url('/assets/images/pages/common/icon_arrow.svg');
      background-size: contain;
    }
  }
}

.l-header__link--acc {
  &::before,
  &::after {
    position: absolute;
    top: 0;
    right: 25px;
    bottom: 0;
    width: 14px;
    height: 1px;
    margin: auto 0;
    content: '';
    background-color: #fff;
  }

  &::after {
    transition: transform 0.25s;
    transform: rotate(-90deg);
  }

  &.is-expand {
    &::after {
      transform: rotate(0deg);
    }
  }
}

.l-header__lower {
  height: 0;
  overflow: hidden;
  transition: height 0.25s;
}

.l-header__lower-list {
  margin-left: 25px;
}

.l-header__lower-item {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.l-header__lower-link {
  position: relative;
  display: flex;
  align-items: center;
  height: 49px;
  padding: 0 15px;
  font-size: 1.2rem;

  &::before {
    position: absolute;
    top: 0;
    right: 25px;
    bottom: 0;
    width: 14px;
    height: 5px;
    margin: auto 0;
    content: '';
    background-image: url('/assets/images/pages/common/icon_arrow.svg');
    background-size: contain;
  }
}

/**
 * PC ナビスタイル
 */

.l-header__gnav {
  margin-top: 14px;
}

.l-header__gnav-other {
  text-align: right;
}

.l-header__gnav-other a {
  position: relative;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.05em;

  &::before {
    position: absolute;
    top: 3px;
    left: -18px;
    width: 18px;
    height: 14px;
    content: '';
    background-image: url('/assets/images/pages/common/icon_header-blank.png');
    background-size: contain;
  }
}

.l-header__gnav-list {
  display: flex;
  height: 40px;
  margin: 17px -10px 0;
}

.l-header__gnav-item {
  &:nth-child(n + 2) {
    margin-left: 21px;
  }
}

.l-header__gnav-link {
  position: relative;
  display: block;
  height: 100%;
  padding: 0 10px;
  font-size: 13px;

  &::before {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 4px;
    content: '';
    background-color: var(--secondarycolor);
    transition: all 0.4s $ease-out-quart;
  }

  &:hover,
  &.is-current {
    &::before {
      left: 0;
      width: 100%;
    }
  }
}

/**
 * 追従 ナビスタイル
 */

.l-header--sticky {
  display: none;

  @include mq(md) {
    position: fixed;
    top: -76px;
    left: 0;
    display: block;
    height: 76px;
    transition: top 0.6s $ease-out-quart;
  }

  &.is-fixed {
    @include mq(md) {
      top: 0;
    }
  }
}

.l-header--sticky .l-header__logo img {
  @include mq(md) {
    width: 123px;
  }
}

.l-header--sticky .l-header__gnav {
  @include mq(md) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-top: 0;
  }
}

.l-header--sticky .l-header__gnav-other {
  @include mq(md) {
    margin-left: 40px;
  }
}

.l-header--sticky .l-header__gnav-list {
  @include mq(md) {
    height: 100%;
    margin-top: 0;
  }
}

.l-header--sticky .l-header__gnav-item {
  @include mq(md) {
    height: 100%;
  }
}

.l-header--sticky .l-header__gnav-link {
  @include mq(md) {
    display: flex;
    align-items: center;
  }
}
