.c-heading {
  padding: 0 25px;
  font-weight: 500;
  opacity: 0;
  transition: all cubic-bezier(0.23, 0.6, 0.43, 0.99) 1s;
  transform: translateY(30px);

  @include mq(md) {
    max-width: 1500px;
    padding: 0 45px;
    margin: 0 auto;
  }

  &.is-active {
    opacity: 1;
    transform: translateY(0);
  }
}

.c-heading-ja,
.c-heading-en {
  display: block;
}

.c-heading-ja {
  font-size: 40px;
  letter-spacing: 0.2em;

  @include mq(md) {
    font-size: 48px;
  }
}

.c-heading-en {
  margin-top: 10px;
  font-family: var(--font-en);
  font-size: 16px;
  color: var(--secondarycolor);
  letter-spacing: 0;

  @include mq(md) {
    margin-top: 22px;
    margin-left: 3px;
    font-size: 32px;
  }
}
