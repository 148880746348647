* {
  padding: 0;
  margin: 0;
}

:root {
  --basecolor: #222;
  --secondarycolor: #fa0046;
  --font-en: 'futura-pt', sans-serif;
}

html {
  min-width: 375px;
  font-size: 62.5%;

  @include mq(md) {
    min-width: 1280px;
  }
}

body {
  font-family: 'source-han-sans-japanese', 'Hiragino Sans', 'ヒラギノ角ゴシック',
    'Meiryo', 'メイリオ', sans-serif;
  font-size: 14px;
  color: var(--basecolor);
  letter-spacing: 0.1em;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-feature-settings: 'palt' 1;

  @include mq(md) {
    font-size: 15px;
  }
}

ol,
ul {
  list-style: none;
}

a {
  color: currentColor;
  text-decoration: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

svg {
  &:not([fill]) {
    fill: currentColor;
  }
}

table {
  border-collapse: collapse;
}

[data-whatinput='mouse'] {
  :focus {
    outline: none;
  }
}
